import React, { useState } from "react"
import { navigate } from "gatsby-link"
import axios from "axios"
import { useForm } from "react-hook-form"

import { usePageContext } from "../pageContext"

import Layout from "../components/Layout/Layout"
import ArrowRight from "../images/arrow.svg"
import ArrowLeft from "../images/arrowLeft.svg"
import LoadingAnimation from "../components/LoadingAnimation/LoadingAnimation"
import JobApplicationForm from "../components/JobApplicationForm/JobApplicationForm"
import Link from "../components/Link/Link"

import "./job-application.scss"

export default function Apply({ location }) {
  const { handleSubmit, errors, register, setValue } = useForm()
  const { lang, title, slug } = usePageContext()

  const [selectedFiles, setSelectedFiles] = useState({})
  const [errorMessage, setErrorMessage] = useState("")
  const [loading, setLoading] = useState(false)

  const onSubmit = data => {
    setLoading(true)

    if (errorMessage) {
      return
    }

    const form = new FormData()
    form.append("firstName", data.firstName)
    form.append("lastName", data.lastName)
    form.append("email", data.email)
    form.append("phoneNumber", data.phoneNumber)
    if (data.department) {
      form.append("department", data.department)
    } else {
      form.append("department", title)
    }
    form.append("body", data.body)
    form.append("resume", data.resume[0])

    axios({
      url: "https://getform.io/f/08dde744-cff0-474f-8fe9-493a0d268826",

      headers: {
        "Content-Type": "formdata",
      },
      method: "POST",
      data: form,
    })
      .then(r => {
        if (r.status === 200) {
          setLoading(false)
          navigate(`/${lang}/careers/apply/success`)
        }
      })
      .catch(r => {
        console.log(r)
      })
  }

  const fileSize = size => {
    if (size === 0) return "0 Bytes"
    const k = 1024
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"]
    const i = Math.floor(Math.log(size) / Math.log(k))
    return parseFloat((size / Math.pow(k, i)).toFixed(2)) + " " + sizes[i]
  }

  const fileType = fileName => {
    return (
      fileName?.substring(fileName?.lastIndexOf(".") + 1, fileName?.length) ||
      fileName
    )
  }

  const validateFile = file => {
    const validTypes = [
      "application/pdf",
      "application/doc",
      "application/docx",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ]
    console.log(file.type)
    if (validTypes.indexOf(file?.type) === -1) {
      return false
    }
    return true
  }

  const handleFiles = files => {
    if (validateFile(files[0])) {
      setSelectedFiles(files[0])
      setValue("resume", files)
      setErrorMessage("")
    } else {
      // add a new property called invalid
      // add to the same array so we can display the name of the file
      // set error message
      files[0]["invalid"] = true
      setSelectedFiles(files[0])
      setErrorMessage("File type not permitted")
    }
  }

  const dragOver = e => {
    e.preventDefault()
  }

  const dragEnter = e => {
    e.preventDefault()
  }

  const dragLeave = e => {
    e.preventDefault()
  }

  const fileDrop = e => {
    e.preventDefault()
    const files = e.dataTransfer.files

    if (files.length === 1) {
      handleFiles(files)
    }
  }

  return (
    <>
      {loading ? (
        <LoadingAnimation />
      ) : (
        <Layout title="Apply">
          <main className="careers-job-application">
            <div className="application-header-container">
              <h1>{title ? title : "Job Form"}</h1>
              {
                title && (
                  <Link to={`/careers/${slug}/`} className="back-button">
                    <p>
                      <span>
                        {lang === "en" ? (
                          <img src={ArrowLeft} alt="Arrow pointing left" />
                        ) : (
                          <img src={ArrowRight} alt="Arrow pointing right" />
                        )}
                      </span>
                      Back to Job Posting{" "}
                    </p>
                  </Link>
                )
                //                 : (
                //             <p className="application-subtitle">
                //             We currently don't have any job openings, but we're always on
                //           the look out for talent. Please submit your details here.
                //       </p>
                //       )
              }
            </div>
            <JobApplicationForm
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              register={register}
              generic={!title}
              dragOver={dragOver}
              dragEnter={dragEnter}
              dragLeave={dragLeave}
              fileDrop={fileDrop}
              fileType={fileType}
              fileSize={fileSize}
              handleFiles={handleFiles}
              selectedFiles={selectedFiles}
              errorMessage={errorMessage}
              errors={errors}
            />
          </main>
        </Layout>
      )}
    </>
  )
}
