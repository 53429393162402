import React from "react"

import Dropdown from "../Dropdown/Dropdown"
import FileUpload from "../FileUpload/FileUpload"

import "./JobApplicationForm.scss"

export default function JobApplicationForm({
  register,
  handleSubmit,
  onSubmit,
  generic,
  dragOver,
  dragEnter,
  dragLeave,
  fileDrop,
  fileSize,
  selectedFiles,
  handleFiles,
  errorMessage,
  errors,
}) {
  return (
    <form className="job-application-form" onSubmit={handleSubmit(onSubmit)}>
      <label htmlFor="firstName">First Name</label>
      <input
        type="text"
        name="firstName"
        id="firstName"
        className="standard-input"
        ref={register({
          required: true,
          pattern: { value: /^[\u0621-\u064Aa-zA-Z ']{1,}$/ },
        })}
      />
      {errors?.firstName?.type === "required" ? (
        <p className="error-message">First Name is required.</p>
      ) : (
        errors?.firstName?.type === "pattern" && (
          <p className="error-message">Please enter a valid first name.</p>
        )
      )}
      <label htmlFor="lastName">Last Name</label>
      <input
        type="text"
        name="lastName"
        id="lastName"
        className="standard-input"
        ref={register({
          required: true,
          pattern: { value: /^[\u0621-\u064Aa-zA-Z ']{1,}$/ },
        })}
      />
      {errors?.lastName?.type === "required" ? (
        <p className="error-message">Last name is required.</p>
      ) : (
        errors?.lastName?.type === "pattern" && (
          <p className="error-message">Please enter a valid last name.</p>
        )
      )}
      <label htmlFor="email">Email</label>
      <input
        type="text"
        name="email"
        id="email"
        className="standard-input"
        ref={register({ required: true })}
      />
      {errors?.email?.type === "required" && (
        <p className="error-message">Email is required.</p>
      )}
      <label htmlFor="phoneNumber">Phone</label>
      <input
        type="text"
        name="phoneNumber"
        id="phoneNumber"
        className="standard-input"
        ref={register({ required: true })}
      />
      {errors?.phoneNumber?.type === "required" && (
        <p className="error-message">Phone is required.</p>
      )}
      {generic && (
        <Dropdown
          htmlFor="department"
          label="Department"
          labelVisible
          selectElementName="department"
          selectElementID="department"
          defaultOption="Department"
          options={{
            edges: [
              { node: { title: "Business Development" } },
              { node: { title: "Operations" } },
              { node: { title: "Marketing" } },
              { node: { title: "Product" } },
              { node: { title: "Data & Technology" } },
              { node: { title: "Talent & Development" } },
            ],
          }}
          register={register({ required: true })}
          errorMessage={
            errors?.department?.type === "required" && (
              <p className="error-message">Department is required.</p>
            )
          }
        />
      )}
      <label htmlFor="body">Body</label>
      <textarea name="body" id="body" ref={register({ required: true })} />
      {errors?.body?.type === "required" && (
        <p className="error-message">Body is required.</p>
      )}
      <label htmlFor="resume">Resume</label>
      <FileUpload
        register={register}
        dragOver={dragOver}
        dragEnter={dragEnter}
        dragLeave={dragLeave}
        fileDrop={fileDrop}
        fileSize={fileSize}
        selectedFiles={selectedFiles}
        handleFiles={handleFiles}
        errorMessage={errorMessage}
      />
      {errors?.resume?.type === "required" && (
        <p className="error-message">CV is required.</p>
      )}
      <input type="submit" value="Submit Your Application" />
    </form>
  )
}
