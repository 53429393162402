import React from "react"

import "./FileUpload.scss"

export default function FileUpload({
  register,
  dragOver,
  dragEnter,
  dragLeave,
  fileDrop,
  fileSize,
  selectedFiles,
  handleFiles,
  errorMessage,
}) {
  return (
    <div
      role="button"
      className="custom-file-upload"
      data-file-input
      onDragOver={dragOver}
      onDragEnter={dragEnter}
      onDragLeave={dragLeave}
      onDrop={fileDrop}
    >
      <label className="file-upload-label" htmlFor="resume">
        <span role="button" className="custom-file-upload-label">
          Upload a file
        </span>{" "}
        or drag and drop here
        <input
          className="file-input"
          type="file"
          name="resume"
          id="resume"
          accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          ref={register({ required: true })}
          multiple={false}
          onChange={e => handleFiles(e.target.files)}
        />
      </label>
      <span
        className="file-upload-output"
        aria-hidden="true"
        key={selectedFiles}
      >
        {selectedFiles?.name ? (
          <>
            {selectedFiles.name}
            {", "}
            {fileSize(selectedFiles.size)}{" "}
            {selectedFiles.invalid && (
              <span className="file-error-message">({errorMessage})</span>
            )}
          </>
        ) : (
          "No file chosen"
        )}
      </span>
    </div>
  )
}
