import React from "react"

import "./Dropdown.scss"

export default function Dropdown({
  htmlFor,
  label,
  labelVisible,
  selectElementName,
  selectElementID,
  defaultOption,
  options,
  register,
  errorMessage,
  onChange = () => {},
}) {
  return (
    <div className="custom-dropdown">
      <label htmlFor={htmlFor} className={labelVisible ? "visible" : ""}>
        {label}
      </label>
      <select
        name={selectElementName}
        id={selectElementID}
        onChange={onChange}
        ref={register}
      >
        <option value="">{defaultOption}</option>
        {options?.edges?.map((option, index) => {
          if (option?.node?.title) {
            return (
              <option value={option?.node?.title} key={index}>
                {option?.node?.title?.toUpperCase()}
              </option>
            )
          }
        })}
      </select>
      {errorMessage}
    </div>
  )
}
